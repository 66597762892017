@import 'src/styles/variables/variables';

@include useTheme();

.Social {
  margin: 32px 0;
  > a {
    margin-right: 30px;
  }

  svg {
    path {
      fill: $core4;
    }
  }
}
