@import 'src/styles/variables/variables';

@include useTheme();

.Desktop {
  display: none;
  @include min-width(desktop) {
    display: block;
  }
}

.Mobile {
  display: block;
  @include min-width(desktop) {
    display: none;
  }

  .Search svg {
    transform: translateY(-2px);
  }
}

.SearchBox {
  display: flex;
  border-radius: 6px;
  align-items: center;

  @include min-width(desktop) {
    background-color: $core2;
    width: 260px;
    height: 36px;
    padding: 0 9px 0 18px;
  }

  @include min-width(medium-desktop) {
    width: 318px;
  }

  &.Focus {
    @include min-width(desktop) {
      outline: 1px solid $tint3;
    }
  }

  input {
    flex: 1 1 auto;
    border: none;
    outline: none;
    color: $core1;
    font-size: 16px;
    line-height: 45px;

    &::placeholder {
      color: $core1;
      font-size: 14px;
    }

    @include min-width(desktop) {
      @include primaryFont(400);
      font-size: 14px;
      line-height: 14px;
    }
  }

  .Spinner {
    padding-right: 6px;
    height: 25px;
    opacity: 0.7;
  }

  .Search {
    flex: 0 0 auto;
    border: none;

    svg {
      height: 17px;
      width: 17px;

      path {
        fill: transparent;
      }
    }
  }
}
