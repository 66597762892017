@import '../../common.scss';


.Opaque {
  background-color: $white;
}

.ButtonsContainer {
  z-index: $zIndex;
  display: flex;
  align-items: center;

  a {
    margin-left: 10px;
    transition: all 0.25s ease;
  }
}
