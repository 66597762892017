@import '../../common.scss';

.CustomerHeader {
  padding: 8px 20px 35px;
}

.SearchWrapper {
  align-items: center;
  border-bottom: 1px solid $shade1 !important;
  display: flex;
  height: 70px;

  input {
    background-color: transparent;
    height: 100%;
    padding-top: 6px;

    &::placeholder {
      font-size: 20px;
      opacity: 0.7;
      color: $core4;
    }
  }

  button {
    background-color: transparent;

    svg {
      margin-right: 10px;
      path {
        stroke: $core4;
        stroke-width: 1.2;
      }
    }

  }
  &.ShowCloseIcon {
    button:last-of-type {
      svg {
        height: 15px;
        width: 15px;
        path {
          fill: $core1;
          stroke: $core1;

        }
      }
    }
  }

}
