@import '../../../common.scss';

.NavLinks {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-right: 3vw;

  .Logo {
    position: relative;
    z-index: $zIndex;
    line-height: 0; // so the div is exactly the height of the SVG

    svg {
      height: 24px;
    }

    path {
      fill: $core1;
      transition: all 0.25s ease;
    }

    &.Conflicting {
      path {
        stroke: $core2;
        fill: $core2;
      }
    }
  }

  .MenuLinkList {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin: 0;
    gap: clamp(5px, 2vw, 49px);

    .MenuLinkContainer {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .MenuLinkTitle {
        position: relative;
        z-index: $zIndex;
        cursor: pointer;
        display: flex;
        height: 100%;
        align-items: center;
        transition: color 0.25s ease;

        &:hover {
          text-decoration: none;
        }

        span:after {
          display: block;
          content: '';
          border-bottom: solid 1px;
          transform: scaleX(0);
          transition: transform 250ms ease-in-out 5ms;
        }

        &:hover span:after {
          transform: scaleX(1);
          text-decoration: none;
        }

        &.Conflicting {
          color: $core2;
        }
      }


      .SubNavContainer {
        left: 0;
        bottom: 1px;
        position: absolute;

        .Outline {
          width: auto;
          top: 0;
          left: 0;
          right: 0;
          position: absolute;
          margin: 0 30px;
        }

        nav {
          display: flex;
          gap: 10px;
          flex-direction: row;
          box-shadow: 0 2px 2px -2px $boxShadow;
          background: $bgColor;
          justify-content: space-around;
          z-index: $zIndex;
          width: 100%;
          transition: all 0.3s;
          position: fixed;
          overflow: hidden;
          max-height: 0;
          opacity: 0;
        }
      }

      &:hover .SubNavContainer nav {
        max-height: $subNavMaxHeight;
        opacity: 1;
        padding: 30px $sidePadding;
      }
    }
  }

  .OverrideWrapper {
    width: 100%;
    text-align: center;
  }
}






