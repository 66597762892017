@import '../common.scss';

.MobileHeader {
  position: relative;
  width: 100%;
  z-index: $zIndex;
  display: block;

  &.isOpen {
    position: fixed;
  }

  p {
    margin: 0;
  }

  a:hover {
    text-decoration: none;
  }

  @include min-width(desktop) {
      display: none;
  }

}

.Nav {
  position: absolute;
  transform: translateX(-100%);
  transition: transform .4s;
  height: 100vh;
  width: 100vw;

  &.isOpen {
    transform: translateX(0);
  }
}

.Header {
  transition: background-color 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $mobileNavHeight;
  padding: 0 15px;
  background-color: $core3;

  &.isScrolled {
    background-color: $core3;
  }

  .isTransparent {
    background-color: transparent;
    &:hover {
      background-color: $core2;
    }
  }

  .Logo {
    svg {
      path {
        stroke-width: 0.3px;
      }
    }

    &.Conflicting:not(.isOpen) {
      svg {
        path {
          stroke: $core2;
          fill: $core2;
        }
      }
    }
  }

  .IconContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }
}

.Hamburguer {
  width: 30px;
  height: 30px;
  vertical-align: baseline;
  margin-left: 15px;
  padding: 0;
  border: none;
  background-color: inherit;

  .Line {
    background-color: $core1;
    height: 1px;
    transition: transform 0.3s ease;
    width: 100%;

    &:nth-child(1) {
      transform: translateY(-10px);
    }

    &:nth-child(2) {
      transform: translateY(0px);
    }

    &:nth-child(3) {
      transform: translateY(10px);
    }
  }

  &.Conflicting:not(.isOpen) {
    .Line {
      background-color: $core2;
    }
  }

  &.isOpen {
    .Line {
      &:nth-child(1) {
        transform: translateY(0px) rotate(-45deg);
      }

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        transform: translateY(0px) rotate(45deg);
      }
    }
  }
}

.Content {
  height: calc(100% - #{$mobileNavHeight});
  background-color: $tint1;
  overflow-y: auto;
}
