@import '../../../common.scss';

.TabHeader {
  width: 100%;
  padding: 21px 0 16px;
  border: none;

  &.isOpen {
    padding-bottom: 0;
  }

  > a {
    display: block;
    text-align: left;
  }

  > div {
    display: flex;
  }
}

.Icon {
  margin-left: auto;
  svg {
    path {
      stroke: $core8;
      fill: $core8;
    }
  }

  .IsPaginated svg path {
    fill: none;
  }
}


.NavLink {
  display: block;
  text-align: left;
  margin-top: 14px;
  padding: 0;
}

.MegaTab {
  flex-direction: column;
  margin-top: 14px;
  margin-bottom: 33px;

  &:last-child {
    margin-bottom: 13px;
  }
}

.MegaTitle {
  display: block;
  text-align: left;
  font-weight: 600;
}

.Divider {
  &.isOpen {
    margin-top: 30px;
  }
  border-bottom: 1px solid $shade1 !important;
}
