@import './common.scss';

.HeaderContainer {
    position: relative;
    width: 100%;
    z-index: $zIndex;
    box-shadow: 0 2px 8px 0 $boxShadow;
    transition: all 0.25s ease;

    &.hasSubNav {
        box-shadow: none;
    }
}

.isPositionFixed {
    position: fixed;
    top: 0;
    z-index: $zIndex;
    box-shadow: none;
    &.isScrolled {
        box-shadow: 0 2px 8px 0 $boxShadow;
    }
}
