@import '../../common.scss';

.Opaque {
  background-color: $white;
}

.ButtonsContainer {
  z-index: $zIndex;
  display: flex;
  align-items: center;
  gap: 24px;
}
