@import '../../common.scss';

.LoggedOutHeader {
  padding: 8px 20px 35px;
}

.SearchWrapper {
  align-items: center;
  border-bottom: 1px solid $shade1 !important;
  display: flex;
  height: 70px;

  input {
    background-color: transparent;
    height: 100%;
    padding-top: 6px;

    &::placeholder {
      font-size: 20px;
      opacity: 0.7;
      color: $core4;
    }
  }

  button {
    background-color: transparent;

    svg {
      margin-right: 10px;
      path {
        stroke: $core4;
        stroke-width: 1.2;
      }
    }

  }
}

.Links {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  > a {
    color: $core4;
    max-width: 120px;
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 54px;
    }
  }
}
