@import 'src/styles/variables/variables';
@import 'src/styles/mixins/mixins';

@include useTheme();

$bgColor: $core2;
$breakpoint-desktop: 1024px;
$boxShadow: rgba(0, 0, 0, 0.1);
$zIndex: $mainNavZIndex;

$maxWidth: 1440px;
$sidePaddingMin: 35px;
$sidePadding: max(#{$sidePaddingMin}, calc((100vw - #{$maxWidth}) / 2 + #{$sidePaddingMin}));
$subNavMaxHeight: 500px;
