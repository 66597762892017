@import '../common.scss';

.DesktopHeader {
  transition: background-color 0.25s ease;
  display: none;
  &:hover {
    background-color: $core2;
  }
  @include min-width(desktop) {
    display: block;
  }

  &.isScrolled {
    background-color: $core2;
  }

  & > nav {
    padding: 0 $sidePadding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: $mainNavHeight;
    min-height: $mainNavHeight;

  }
}
