.Page {
  position:relative;
  display: flex;
  flex-direction: column;

  &.Fullscreen {
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */ 
      height: 100%;
    }
    
    @supports not (-webkit-touch-callout: none) {
      /* CSS for other than iOS devices */ 
      height: 100vh;
    }

    transition: height 100ms ease-in-out;
  }
}

